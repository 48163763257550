import { Injectable } from '@angular/core';
const holdDelimiters = ['DC Lab Hold'];
const STATUS_MESSAGES = {
  cancelled: 'Frame order status not available <br/> Please call customer service at 1-855-658-1705 for information.',
  contactCustomerService: 'Please contact Customer Service for more information at 1-855-658-1705',
  invalidRx:
    'Based on the RX ID entered, we are unable to match a valid RX order to your frame order.  We will continue to search for your RX order and process it as soon as located, but this might delay delivery.  In the future, providing a valid RX ID will ensure  the fastest possible delivery. <br/><br/>Your Rx Order ID can be found in the following systems as:<br/> &nbsp;&nbsp;&nbsp; • VisionWeb: E-Order # (i.e. SP1UF1WF)<br/> &nbsp;&nbsp;&nbsp; • Eyefinity: Lab Order / Authorization # (i.e. 43509121)<br/> &nbsp;&nbsp;&nbsp;  • EyeMed: E-Order # (i.e. SP1UJ1MB)<br/><br/>Please contact Customer Service for more information at 1-855-658-1705.',
  outOfStock:
    'More frames are on the way from the vendor. Your order will be prioritized and processed as soon as possible',
  rxNotYetAvailable: 'Rx Order information may take hours to display in our systems',
  invalidRxHours: 12,
  completeJob: 'Frame + Rx order',
  framePatient: 'Frame only order',
  completeJobDTP: 'Frame + Rx order (Direct To Patient)',
  frameOtherLab: 'Required managed care lab order',
  deploymentDate: '2017-11-30T14:57:00',
  rxIncorrectLabMessage: 'Incorrect Lab Message',
  dcLabHoldMessage: 'DC Lab Hold Message',
  rxOrderNotProcessed: 'Rx Order Not Processed',
  frameIncorrectLabMessage: 'Frame Incorrect Message',
};
/**
 * HelperService
 */
@Injectable({ providedIn: 'root' })
export class HelperService {
  /**
   * Covert array of objects to flat object,
   * using the given key as accessor key on the flatted object.
   *
   * @param arr any[]
   * @param key string
   * @return Object
   */
  static normalize(arr: any[], key: string): any {
    return Object.assign({}, ...arr.map(item => ({ [item[key]]: item })));
  }

  /**
   * checkIfForBoard
   * @param name
   */
  public checkIfForBoard(name) {
    if (name.toLowerCase() === 'forboard') {
      return 'Frame only For Board';
    }

    if (name.toLowerCase() === 'forpatienttopatient') {
      return 'Complete Job';
    }

    if (name.toLowerCase() === 'ForCompleteJobMTP') {
      return 'complete job - dtp';
    }

    if (name.toLowerCase() === 'forpatienttooffice') {
      return 'Frame Only For Patient';
    }

    return name;
  }

  /**
   * checkAvailability
   * @param param
   */
  public checkAvailability(param) {
    if (param) return param;
    else return 'Not available';
  }

  public convertToLocalDate(date) {
    let oldDate = new Date(date); // Convert to date
    let utcDate = new Date(oldDate); // Convert and Parse to make UTC
    let newDate = utcDate.toLocaleDateString(); // Convert to Local Date
    let elements = newDate.split('/', 3); // Split Month, Day, and Year into an array

    let nullDate = new Date('01/01/2001');
    if (newDate !== nullDate.toLocaleDateString())
      return this.padLeftZero(elements[0]) + '/' + this.padLeftZero(elements[1]) + '/' + elements[2];
    // Converted, Sample: 09/05/2017
    else return '';
  }

  public padLeftZero(num) {
    if (num < 10) return '0' + num;
    else return num;
  }

  public isMidland(name) {
    return (
      name &&
      (name.toLowerCase().indexOf('midland') !== -1 ||
        name.toLowerCase().indexOf('pech') !== -1 ||
        name.toLowerCase().indexOf('bartley') !== -1)
    );
  }

  /**
   * getContactInfo
   * @param order
   */
  public getContactInfo(order) {
    if (order.dropship.name && order.dropship.name.toLowerCase().indexOf('midland') !== -1)
      return 'Midland Optical at 1-800-325-3176';
    else if (order.dropship.name && order.dropship.name.toLowerCase().indexOf('pech') !== -1)
      return 'Pech Optical at 1-800-831-2352';
    else if (order.dropship.name && order.dropship.name.toLowerCase().indexOf('bartley') !== -1)
      return 'Bartley Optical at 1-800-347-4733';
  }

  /**
   * interumOrderRow
   * @param order
   */
  public interumOrderRow(order) {
    return this.isMidland(order.dropship.name);
  }

  /**
   *checkLineUseType
   * @param order
   */
  public checkLineUseType(order) {
    let _type = order.lineUseType ? order.lineUseType.toLowerCase() : '';
    let _orderType = order.lineItems[0].PatientName ? order.lineItems[0].orderType.toLowerCase() : '';

    // =========================
    // No record in Db
    // =========================
    if (_type === '' || _type === null) {
      // Deployment date config must be set to Central Time Zone
      let deploymentDate = new Date(STATUS_MESSAGES.deploymentDate);
      let orderDate = new Date(order.orderDate);

      // =====================================================
      // To implement forPatientToLab even it is complete job
      // =====================================================
      if (orderDate < deploymentDate) {
        // Frame only
        if (order.lineItems[0].rxId === null || order.lineItems[0].rxId === '') {
          if (order.lineItems[0].orderType === 'ForBoard' || order.lineItems[0].orderType === 'ForPatientToOffice')
            return STATUS_MESSAGES.framePatient;
          else if (order.lineItems[0].orderType === 'ForPatientToLab') return STATUS_MESSAGES.frameOtherLab;
        }
        // Frame + Rx
        else return STATUS_MESSAGES.completeJob;
      }
      // =====================================================================
      // To implement new logic for complete job to be a forPatientToPatient
      // =====================================================================
      else {
        // Frame only
        if (order.lineItems[0].rxId === null || order.lineItems[0].rxId === '') {
          if (order.lineItems[0].orderType === 'ForBoard' || order.lineItems[0].orderType === 'ForPatientToOffice')
            return STATUS_MESSAGES.framePatient;
          else if (order.lineItems[0].orderType === 'ForPatientToLab') return STATUS_MESSAGES.frameOtherLab;
        }
        // Frame + Rx
        else {
          if (order.lineItems[0].orderType === 'ForPatientToLab') return STATUS_MESSAGES.frameOtherLab;
          if (order.lineItems[0].orderType === 'ForPatientToPatient') return STATUS_MESSAGES.completeJob;
          if (order.lineItems[0].orderType === 'ForCompleteJobMTP') return STATUS_MESSAGES.completeJobDTP;
        }
      }
    }
    // Has record
    else {
      if (_type === 'complete job') {
        return STATUS_MESSAGES.completeJob;
      } else if (_type === 'frame only (patient)' || _type === 'frame only (board)' || _orderType === 'forboard')
        return STATUS_MESSAGES.framePatient;
      else if (_type === 'frame only (other lab)') return STATUS_MESSAGES.frameOtherLab;
      else if (_type === 'complete job - dtp') return STATUS_MESSAGES.completeJobDTP;
      else {
        return STATUS_MESSAGES.completeJob;
      }
    }
  }

  /**
   *
   * @param paramDate
   */
  public checkAvailabilityOfRxOrder(paramDate) {
    let centralDate = new Date(paramDate); // Convert to date

    // Get the current date
    let currentDate = new Date();

    // Get the number of day for CDT/CST
    let marchSecondSunday = this.getMonthWithDay(2, 'Sunday', 'March', currentDate.getFullYear());
    let novemberFirstSunday = this.getMonthWithDay(1, 'Sunday', 'November', currentDate.getFullYear());
    let orderDate;

    // Convert to date for CDT/CST
    let marchDate = new Date(currentDate.getFullYear() + '-03-' + marchSecondSunday + 'T02:00:00');
    let novemberDate = new Date(currentDate.getFullYear() + '-11-' + novemberFirstSunday + 'T02:00:00');

    // Convert from Central Time to Local Time
    if (centralDate >= marchDate && centralDate <= novemberDate) orderDate = new Date(centralDate + ' UTC -5');
    else orderDate = new Date(centralDate + ' UTC -6');

    // @ts-ignore
    let diff = (orderDate - currentDate) / 1000;
    diff = Math.abs(Math.floor(diff));

    let days = Math.floor(diff / (24 * 60 * 60));
    let leftSec = diff - days * 24 * 60 * 60;

    let hours = Math.floor(leftSec / (60 * 60));
    //let leftSec = leftSec - hours * 60 * 60;

    let totalHours = days * 24 + hours;
    if (totalHours >= STATUS_MESSAGES.invalidRxHours) {
      return {
        status: 'Invalid Rx ID Entered',
        detail: STATUS_MESSAGES.invalidRx,
      };
    } else {
      return {
        status: 'Rx order information not yet available',
        detail: STATUS_MESSAGES.rxNotYetAvailable.replace(
          /\\hours/g,
          this.convertToDay(STATUS_MESSAGES.invalidRxHours),
        ),
      };
    }
  }

  /**
   * convertToDay
   * @param hour
   */
  public convertToDay(hour) {
    let day = hour / 24;
    // var intDay = parseInt(day);
    let remainHour = hour % 24;
    if (hour > 23) {
      if (remainHour === 0) {
        return 'up to ' + parseInt(String(day), 0) + ' day(s)';
      } else return 'between ' + parseInt(String(day), 0) + ' and ' + (parseInt(String(day), 0) + 1) + ' day(s)';
    } else return 'up to ' + hour + ' hour(s)';
  }

  /**
   *
   * @param order
   */
  public getFrameStatus(order) {
    // ====================================
    // Checking of null and empty strings
    // Convert to null those have values
    // ====================================
    let fd_lineUseType = order.lineUseType == null || order.lineUseType === '' ? null : order.lineUseType.toLowerCase();
    let fd_sqlOrderStatus =
      order.sqlOrderStatus == null || order.sqlOrderStatus === '' ? null : order.sqlOrderStatus.toLowerCase();
    let fd_sqlRXStatus = order.sqlRXStatus == null ? '' : order.sqlRXStatus.toLowerCase();
    // let fd_SqlRXOrderNumber = order.sqlRXOrderNumber == null || order.sqlRXOrderNumber == "" ? null : order.sqlRXOrderNumber;
    let fd_RxOrderPlacedInLabID =
      order.rxOrderPlacedInLabID == null || order.rxOrderPlacedInLabID === '' ? null : order.rxOrderPlacedInLabID;
    //let fd_RxOrderPlacedInLabName =  order.rxOrderPlacedInLabName == null || order.rxOrderPlacedInLabName == ""  ? null : order.rxOrderPlacedInLabName;

    // ===========================
    // For debugging purpose only
    // ===========================
    //if (order.orderNumber == 'FR2B7KDS' || order.orderNumber == 'FR2B6DMC') {
    //    console.log(order);
    //}

    // ========================
    // For not existing in DB
    // ========================
    if (fd_lineUseType == null) {
      // Deployment date config must be set to Central Time Zone
      let deploymentDate = new Date(STATUS_MESSAGES.deploymentDate);
      let orderDate = new Date(order.orderDate);

      // =====================================================
      // To implement forPatientToLab even it is complete job
      // =====================================================
      if (orderDate < deploymentDate) {
        // For complete job
        if (order.lineItems[0].rxId != null && order.lineItems[0].rxId !== '') {
          if (order.status != null) return { status: 'Frame order received', detail: '' };
          else return { status: '', detail: '' };
        }
        // For frame only
        else {
          return { status: 'Order received', detail: '' };
        }
      }
      // =====================================================================
      // To implement new logic for complete job to be a forPatientToPatient
      // =====================================================================
      else {
        // For complete job
        if (order.lineItems[0].rxId != null && order.lineItems[0].rxId !== '') {
          if (order.lineItems[0].orderType === 'ForPatientToPatient')
            return { status: 'Frame order received', detail: '' };
          else if (order.lineItems[0].orderType === 'ForPatientToLab') {
            // ===========================
            // 20180425 - Marc Jason Crusina
            // To handle Vision Source Midland with ordertype as ForPatientToLab
            // ===========================
            if (this.isMidland(order.dropship.name)) return { status: 'Frame order received', detail: '' };
            else return { status: 'Order received', detail: '' };
          } else return { status: '', detail: '' };
        }
        // For frame only
        else {
          return { status: 'Order received', detail: '' };
        }
      }
    }
    // ===================================
    // For complete job or Frame with RX
    // ===================================
    else if ((fd_lineUseType === 'complete job') || (fd_lineUseType === 'complete job - dtp')) {
      // ===========================
      // Scenario 2 (Found in Elink)
      // Value is 1
      // ===========================
      alert('complete job')
      if (order.rxOrderFoundInELINK === 1) {
        // Frame order received
        if (fd_sqlOrderStatus === null || fd_sqlOrderStatus === 'ready to release' || fd_sqlOrderStatus === 'book') {
          // For scenario 2.2.2 when the framestatus is null and the rx status is mail out
          if (fd_sqlRXStatus === 'mail out')
            return {
              status: 'Frame order received',
              detail: STATUS_MESSAGES.contactCustomerService,
            };
          else return { status: 'Frame order received', detail: '' };
        }
        // Frame Order Status not available ...
        else if (fd_sqlRXStatus === 'cancelled' && fd_sqlOrderStatus !== 'cancelled') {
          if (fd_sqlOrderStatus !== 'cancelled') {
            return {
              status: 'Frame order status not available',
              detail: STATUS_MESSAGES.contactCustomerService,
            };
          } else {
            return { status: '', detail: '' };
          }
        }
        // Frame order being prepared
        else if (fd_sqlOrderStatus === 'released to warehouse' || fd_sqlOrderStatus === 'staged/pick confirmed') {
          if (fd_sqlRXStatus === 'mail out')
            return {
              status: 'Frame order being prepared',
              detail: STATUS_MESSAGES.contactCustomerService,
            };
          else return { status: 'Frame order being prepared', detail: '' };
        } else if (fd_sqlOrderStatus === 'shipped') {
          // Display with shipped on date only
          if (fd_sqlRXStatus === 'mail out')
            return {
              status: 'Shipped on ' + this.convertToLocalDate(order.sqlRXOrderTimeStamp),
              detail: '',
            };
          // Display with Frame and Rx status
          else return { status: 'Frame at lab', detail: '' };
        }
        // Frame Cancelled
        else if (fd_sqlOrderStatus === 'cancelled') {
          return {
            status: 'Frame cancelled on ' + this.convertToLocalDate(order.sqlOrderStatusDate),
            detail: '',
          };
        }
        // Out of stock
        else if (fd_sqlOrderStatus === 'backordered') {
          return {
            status: 'Frame temporarily out of stock',
            detail: STATUS_MESSAGES.outOfStock,
          };
        } else return { status: '', detail: '' };
      }
      // ===============================
      // Scenario 3 (Not found in ELink)
      // Value is 0
      // ===============================
      else {
        // ========================
        // Placed at correct lab
        // ========================
        if (!fd_RxOrderPlacedInLabID) {
          // Frame order being prepared
          if (fd_sqlOrderStatus === 'released to warehouse' || fd_sqlOrderStatus === 'staged/pick confirmed')
            return { status: 'Frame order being prepared', detail: '' };
          // Frame order at lab or shipped
          else if (fd_sqlOrderStatus === 'shipped') {
            return {
              status: 'Frame order at lab or shipped',
              detail: STATUS_MESSAGES.contactCustomerService,
            };
          }
          // Out of stock
          else if (fd_sqlOrderStatus === 'backordered') {
            return {
              status: 'Frame temporarily out of stock',
              detail: STATUS_MESSAGES.outOfStock,
            };
          }
          // Frame Cancelled
          else if (fd_sqlOrderStatus === 'cancelled')
            return {
              status: 'Frame cancelled on ' + this.convertToLocalDate(order.sqlOrderStatusDate),
              detail: '',
            };
          else return { status: 'Frame order received', detail: '' };
        }
        // ========================
        // Placed at incorrect lab
        // ========================
        else {
          return {
            status: 'Frame shipping information not available',
            detail: STATUS_MESSAGES.frameIncorrectLabMessage,
          };
        }
      }
    }
    // ===============
    // For Frame Only
    // ===============
    else {
      // =================
      // Not complete job
      // =================
      if (fd_lineUseType !== 'complete job') {
        // ======================================
        // Scenario 1 (For Patient & For Board)
        // ======================================
        if (fd_lineUseType === 'frame only (patient)' || fd_lineUseType === 'frame only (board)') {
          if (fd_sqlOrderStatus === null || fd_sqlOrderStatus === 'ready to release' || fd_sqlOrderStatus === 'book')
            return { status: 'Order received', detail: '' };
          else if (
            fd_sqlOrderStatus === 'released to warehouse' ||
            fd_sqlOrderStatus === 'staged/pick confirmed' ||
            fd_sqlOrderStatus === 'ready to release'
          )
            return { status: 'Order being prepared', detail: '' };
          else if (fd_sqlOrderStatus === 'shipped') {
            // Display with shipped on date only
            return {
              status: 'Shipped on ' + this.convertToLocalDate(order.sqlOrderStatusDate),
              detail: '',
            };
          } else if (fd_sqlOrderStatus === 'cancelled') {
            return {
              status: 'Cancelled on ' + this.convertToLocalDate(order.sqlOrderStatusDate),
              detail: '',
            };
          } else if (fd_sqlOrderStatus === 'backordered') {
            return {
              status: 'Temporarily out of stock',
              detail: STATUS_MESSAGES.outOfStock,
            };
          } else return { status: '', detail: '' };
        }

        // =======================
        // Scenario 4 (Other lab)
        // =======================
        else if (
          fd_lineUseType === 'frame only (other lab)' ||
          fd_lineUseType === 'complete job - partner lab' ||
          fd_lineUseType === 'other'
        ) {
          if (fd_sqlOrderStatus === null || fd_sqlOrderStatus === 'ready to release' || fd_sqlOrderStatus === 'book')
            return { status: 'Order received', detail: '' };
          else if (
            fd_sqlOrderStatus === 'released to warehouse' ||
            fd_sqlOrderStatus === 'staged/pick confirmed' ||
            fd_sqlOrderStatus === 'ready to release'
          ) {
            // ===========================
            // 20180508 - Marc Jason Crusina
            // To handle Vision Source Midland with ordertype as ForPatientToLab
            // ===========================
            if (this.isMidland(order.dropship.name)) return { status: 'Frame order being prepared', detail: '' };
            else return { status: 'Order being prepared', detail: '' };
          } else if (fd_sqlOrderStatus === 'shipped') {
            // ===========================
            // 20180508 - Marc Jason Crusina
            // To handle Vision Source Midland with ordertype as ForPatientToLab
            // ===========================
            if (this.isMidland(order.dropship.name))
              return {
                status:
                  'Frame shipped to ' +
                  order.dropship.name +
                  ' on ' +
                  this.convertToLocalDate(order.sqlOrderStatusDate),
                detail: '',
              };
            else
              return {
                status:
                  'Shipped to ' + order.dropship.name + ' on ' + this.convertToLocalDate(order.sqlOrderStatusDate),
                detail: '',
              }; // Frame only other lab
          } else if (fd_sqlOrderStatus === 'cancelled') {
            return {
              status: 'Cancelled on ' + this.convertToLocalDate(order.sqlOrderStatusDate),
              detail: '',
            };
          } else if (fd_sqlOrderStatus === 'backordered') {
            return {
              status: 'Temporarily out of stock',
              detail: STATUS_MESSAGES.outOfStock,
            };
          } else return { status: '', detail: '' };
        }
      } else return { status: '', detail: '' };
    }
  }
  /**
   * getRxStatus
   * @param order
   */
  public getRxStatus(order) {
    // ====================================
    // Checking of null and empty strings
    // Convert to null those have values
    // ====================================
    let fd_lineUseType =
      order.lineUseType === null || order.lineUseType === '' ? null : order.lineUseType.toLowerCase();
    let fd_sqlOrderStatus =
      order.sqlOrderStatus === null || order.sqlOrderStatus === '' ? null : order.sqlOrderStatus.toLowerCase();
    let fd_sqlRXStatus = order.sqlRXStatus === null ? '' : order.sqlRXStatus.toLowerCase();
    //  let fd_SqlRXOrderNumber =order.sqlRXOrderNumber === null || order.sqlRXOrderNumber === '' ? null : order.sqlRXOrderNumber;
    let fd_RxOrderPlacedInLabID =
      order.rxOrderPlacedInLabID === null || order.rxOrderPlacedInLabID === '' ? null : order.rxOrderPlacedInLabID;
    let fd_RxOrderPlacedInLabName =
      order.rxOrderPlacedInLabName === null || order.rxOrderPlacedInLabName === ''
        ? null
        : order.rxOrderPlacedInLabName;

    // =========================
    // For not existing in DB
    // ========================
    if (fd_lineUseType == null) {
      // Deployment date config must be set to Central Time Zone
      let deploymentDate = new Date(STATUS_MESSAGES.deploymentDate);
      let orderDate = new Date(order.orderDate);

      // =====================================================
      // To implement forPatientToLab even it is complete job
      // =====================================================
      if (orderDate < deploymentDate) {
        if (order.lineItems[0].rxId !== null && order.lineItems[0].rxId !== '') {
          if (order.status !== null) return this.checkAvailabilityOfRxOrder(order.orderDate);
          else return { status: '', detail: '' };
        }
        // For frame only
        else {
          return { status: '', detail: '' };
        }
      }
      // =====================================================================
      // To implement new logic for complete job to be a forPatientToPatient
      // =====================================================================
      else {
        if (order.lineItems[0].rxId !== null && order.lineItems[0].rxId !== '') {
          if (order.lineItems[0].orderType === 'ForPatientToPatient')
            return this.checkAvailabilityOfRxOrder(order.orderDate);
          else if (order.lineItems[0].orderType === 'ForPatientToLab') {
            return { status: '', detail: '' };
          } else return { status: '', detail: '' };
        }
        // For frame only
        else {
          return { status: '', detail: '' };
        }
      }
    }

    // ===================================
    // For complete job or Frame with RX
    // ===================================
    if (fd_lineUseType === 'complete job') {
      // ===========================
      // Scenario 2 (Found in Elink)
      // Value is 1
      // ===========================
      if (order.rxOrderFoundInELINK === 1) {
        for (let i = 0; i < holdDelimiters.length; i++) {
          if (fd_sqlRXStatus === holdDelimiters[i].toLowerCase()) {
            return {
              status: '<span class="errorColor">' + STATUS_MESSAGES.rxOrderNotProcessed + '</span>',
              detail: STATUS_MESSAGES.dcLabHoldMessage,
            };
          }
        }
        // When Rx Status is null or combination of status of Rx null with FrameStatusLine backordered
        if (
          fd_sqlRXStatus == null ||
          fd_sqlRXStatus === '' ||
          (fd_sqlRXStatus == null && fd_sqlOrderStatus === 'backordered')
        ) {
          return { status: 'Rx order received', detail: '' };
        }
        // When Rx Status is not mail out and cancelled or combination of status of rx(mail out and cancelled) and FrameStatusLine is backordered
        else if (
          (fd_sqlRXStatus !== 'mail out' && fd_sqlRXStatus !== 'cancelled') ||
          (fd_sqlRXStatus !== 'mail out' && fd_sqlRXStatus !== 'cancelled' && fd_sqlOrderStatus === 'backordered')
        )
          return { status: 'Rx order in process', detail: '' };
        // Rx cancelled
        else if (
          fd_sqlRXStatus === 'cancelled' ||
          (fd_sqlRXStatus === 'cancelled' && fd_sqlOrderStatus !== 'cancelled')
        ) {
          return {
            status: 'Rx cancelled on ' + this.convertToLocalDate(order.sqlRXOrderTimeStamp),
            detail: '',
          };
        }
        // When FrameStatusLine is not shipped then Rx Status is mail out
        else if (fd_sqlRXStatus === 'mail out' && fd_sqlOrderStatus !== 'shipped')
          return {
            status: 'Rx shipped on ' + this.convertToLocalDate(order.sqlRXOrderTimeStamp),
            detail: '',
          };
        else {
          return { status: '', detail: '' };
        }
      }
      // ================================
      // Scenario 3 (Not Found in Elink)
      // Value is 0
      // ================================
      else {
        // ========================
        // Placed at correct lab
        // ========================
        if (!fd_RxOrderPlacedInLabID) {
          return this.checkAvailabilityOfRxOrder(order.orderDate);
        }
        // ========================
        // Placed at incorrect lab
        // ========================
        else {
          return {
            status: 'Rx order placed at incorrect lab - ' + fd_RxOrderPlacedInLabName,
            detail: STATUS_MESSAGES.rxIncorrectLabMessage,
          };
        }
      }
    }
    // ===================
    // Scenario 1 & 4
    // ===================
    else if (fd_lineUseType !== 'complete job') {
      return { status: '', detail: '' };
    } else return { status: '', detail: '' };
  }

  public getMonthWithDay(n, d, m, y) {
    let targetDay,
      finalTargetDate,
      newtargetday,
      curDay = 0,
      i = 1,
      seekDay;
    if (d === 'Sunday') seekDay = 0;
    if (d === 'Monday') seekDay = 1;
    if (d === 'Tuesday') seekDay = 2;
    if (d === 'Wednesday') seekDay = 3;
    if (d === 'Thursday') seekDay = 4;
    if (d === 'Friday') seekDay = 5;
    if (d === 'Saturday') seekDay = 6;
    while (curDay < n && i < 31) {
      targetDay = new Date(i++ + ' ' + m + ' ' + y);
      if (targetDay.getDay() === seekDay) curDay++;
    }
    if (curDay === n) {
      newtargetday = targetDay.getDate();
      if (newtargetday < 10) finalTargetDate = '0' + newtargetday;
      else finalTargetDate = newtargetday;
      return finalTargetDate;
    } else {
      return false;
    }
  }
}
